<template>
  <!-- Breadcroumbs start -->
  <Breadcrumbs title="My Profile" />
  <!-- Breadcroumbs end -->
  <!-- Edit Profile start -->
  <div class="bee-content-block">
    <div class="container">
      <div class="row flex-lg-row-reverse">
        <div class="col-12 col-lg-9">
          <div class="my-account-page-title">
            <h3>Edit Profile</h3>
          </div>
          <div class="order-form">
            <form action="#">
              <h5>Personal information</h5>
              <div class="form-group">
                <div class="row">
                  <div class="col-12 col-sm-6 col-md-6 col-lg-6">
                    <label>Your Name:</label>
                    <input
                      type="text"
                      class="form-control"
                      name="fname"
                      v-model="profile.firstName"
                      placeholder="First Name"
                    />
                  </div>
                  <div class="col-12 col-sm-6 col-md-6 col-lg-6">
                    <label>Last Name:</label>
                    <input
                      type="text"
                      class="form-control"
                      name="lname"
                      v-model="profile.lastName"
                      placeholder="Last Name"
                    />
                  </div>
                </div>
              </div>
              <div class="form-group">
                <div class="row">
                  <div class="col-12">
                    <label>Address:</label>
                    <input
                      type="text"
                      class="form-control"
                      name="phone"
                      v-model="profile.address"
                    />
                  </div>
                </div>
              </div>
              <div class="form-group">
                <div class="row">
                  <div class="col-12 col-sm-6 col-md-6 col-lg-6">
                    <label>City:</label>
                    <input
                      type="text"
                      class="form-control"
                      name="city"
                      v-model="profile.city"
                    />
                  </div>
                  <div class="col-12 col-sm-6 col-md-6 col-lg-6">
                    <label>State:</label>
                    <input
                      type="text"
                      class="form-control"
                      name="state"
                      v-model="profile.state"
                    />
                  </div>
                </div>
              </div>
              <div class="form-group">
                <div class="row">
                  <div class="col-12 col-sm-6 col-md-6 col-lg-6">
                    <label>post code:</label>
                    <input
                      type="text"
                      class="form-control"
                      name="post"
                      v-model="profile.postcode"
                    />
                  </div>
                  <div class="col-12 col-sm-6 col-md-6 col-lg-6">
                    <label>Country:</label>
                    <select class="form-control" name="country">
                      <option value="Alabama">Alabama</option>
                      <option value="Bangladesh">Bangladesh</option>
                      <option value="Canada">Canada</option>
                      <option value="Japan">Japan</option>
                      <option value="United State">United State</option>
                    </select>
                  </div>
                </div>
              </div>
              <div class="form-group">
                <div class="row">
                  <div class="col-12 col-sm-6 col-md-6 col-lg-6">
                    <label>Email:</label>
                    <input type="email" class="form-control" name="email" />
                  </div>
                  <div class="col-12 col-sm-6 col-md-6 col-lg-6">
                    <label>Phone:</label>
                    <input
                      type="text"
                      class="form-control"
                      name="phone"
                      v-model="profile.city"
                    />
                  </div>
                </div>
              </div>
              <h5>Shipment information</h5>
              <div class="checkbox mb20">
                <input
                  type="checkbox"
                  name="remember"
                  id="remember"
                  class="css-checkbox"
                />
                <label for="remember" class="css-label"
                  >Same as defalt address</label
                >
              </div>
              <div class="form-group">
                <div class="row">
                  <div class="col-12 col-sm-6 col-md-6 col-lg-6">
                    <label>Your Name:</label>
                    <input type="text" class="form-control" name="fname" />
                  </div>
                  <div class="col-12 col-sm-6 col-md-6 col-lg-6">
                    <label>Last Name:</label>
                    <input type="text" class="form-control" name="lname" />
                  </div>
                </div>
              </div>
              <div class="form-group">
                <div class="row">
                  <div class="col-12">
                    <label>Address:</label>
                    <input type="text" class="form-control" name="phone" />
                  </div>
                </div>
              </div>
              <div class="form-group">
                <div class="row">
                  <div class="col-12 col-sm-6 col-md-6 col-lg-6">
                    <label>City:</label>
                    <input type="text" class="form-control" name="city" />
                  </div>
                  <div class="col-12 col-sm-6 col-md-6 col-lg-6">
                    <label>State:</label>
                    <input type="text" class="form-control" name="state" />
                  </div>
                </div>
              </div>
              <div class="form-group">
                <div class="row">
                  <div class="col-12 col-sm-6 col-md-6 col-lg-6">
                    <label>zip code:</label>
                    <input type="text" class="form-control" name="zip" />
                  </div>
                  <div class="col-12 col-sm-6 col-md-6 col-lg-6">
                    <label>Country:</label>
                    <select class="form-control" name="country">
                      <option value="Alabama">Alabama</option>
                      <option value="Bangladesh">Bangladesh</option>
                      <option value="Canada">Canada</option>
                      <option value="Japan">Japan</option>
                      <option value="United State">United State</option>
                    </select>
                  </div>
                </div>
              </div>
              <div class="form-group">
                <div class="row">
                  <div class="col-12 col-sm-6 col-md-6 col-lg-6">
                    <label>Email:</label>
                    <input type="email" class="form-control" name="email" />
                  </div>
                  <div class="col-12 col-sm-6 col-md-6 col-lg-6">
                    <label>Phone:</label>
                    <input type="text" class="form-control" name="phone" />
                  </div>
                </div>
              </div>
              <button
                type="button"
                @click="updateProfile"
                class="btn btn-submit"
              >
                Update now
              </button>
            </form>
          </div>
        </div>
        <ProfileNavigation />
      </div>
    </div>
  </div>
  <!-- Edit Profile End -->

  <Newsletter />
</template>

<script>
// @ is an alias to /src
import { auth, profilesCollection } from "@/includes/firebase";

import Breadcrumbs from "@/components/Breadcrumbs.vue";
import ProfileNavigation from "@/components/ProfileNavigation.vue";
import Newsletter from "@/components/Newsletter.vue";

export default {
  name: "Profile",
  data() {
    return {
      profile: {
        firstName: null,
        lastName: null,
        phone: null,
        address: null,
        postcode: null,
        city: null,
        state: null,
      },
      account: {
        name: null,
        email: null,
        photoUrl: null,
        emailVerified: null,
        password: null,
        confirmPassword: null,
        uid: null,
      },
      uid: null,
    };
  },
  components: {
    Breadcrumbs,
    Newsletter,
    ProfileNavigation,
  },
  methods: {
    updateProfile() {
      profilesCollection.doc(this.uid).update(this.profile);
      console.log(this.profile);
    },
  },

  created() {
    auth.onAuthStateChanged((user) => {
      if (user) {
        // User is signed in.

        const { uid } = user;

        this.uid = uid;
        // ...
      } else {
        console.log("No user is signed in");
      }
    });
  },
};
</script>
